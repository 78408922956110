export default function getGeoLocation() {
  return new Promise((resolve, reject) => {
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(
        (position) => {
          const coords = {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          };
          resolve(coords); // Resolve with the coordinates
        },
        (error) => {
          console.error('Error fetching geolocation', error);
          resolve({ latitude: '', longitude: '' }); // Resolve with empty values in case of error
        }
      );
    } else {
      resolve({ latitude: '', longitude: '' }); // Resolve with empty values if geolocation is not supported
    }
  });
}