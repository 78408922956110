import './stepperProgress.css'
import {useEffect, useState} from "react";
import moment from "moment";
import {useTranslation} from "../../../../contexts/TranslationContext";




export default function StepperProgress({request}) {
  const {translate} = useTranslation();
  const [currentStep, setCurrentStep] = useState(null)

 useEffect(() => {
   if(request ) {
       if(request.APPLICATION_STATUS === 'D')  setCurrentStep(0)
       if(request.APPLICATION_STATUS === 'S')  setCurrentStep(1)
       if(request.OFFER_STATUS)setCurrentStep(2)
       if(request.CONTRACT_STATUS === 'SIGNED' || request.CONTRACT_STATUS === 'GENERATED')  setCurrentStep(3)
       if(request.OFFER_STATUS === 'REJECTED' )  setCurrentStep(2)
       if(request.CONTRACT_STATUS === 'REJECTED') setCurrentStep(3);

   }

 },[])


  const steps = [
    {
      id: 0,
      stepTitle: translate('APPLY'),
      status: 'D'
    },
    {
      id: 1,
      stepTitle: translate('SUBMIT'),
      status: 'S'
    },
    {
      id: 2,
      stepTitle: translate('STEP_OFFER'),
      date: '23/02/2024',
      status: 'O'
    },
    {
      id: 3,
      stepTitle: translate('STEP_CONTRACT'),
      date: '24/02/2024',
      status: 'C'
    },
    {
      id: 4,
      stepTitle: translate('DISBURSEMENNT'),
      status: 'DI'
    }
  ];



    const getOfferDate = () => {
        if (request.OFFER_STATUS === 'GENERATED') return request.OFFER_DATE;
        else return request.OFFER_ACCEPT_DATE

    };

    const getContractDate = () => {
        if (request.CONTRACT_STATUS === 'GENERATED') return request.CONTRACT_DATE;
        if (request.CONTRACT_STATUS === 'SIGNED') return request.CONTRACT_SIGN_DATE;
        return false;
    };

    const stepDate = (stepId) => {
        switch (stepId) {
            case 0:
                return request.CREATED_ON;

            case 1:
                return request.SUBMITED_ON;

            case 2:
                return getOfferDate();

            case 3:
                return getContractDate();

            case 4:
                return request.DISBURSED_ON;

            default:
                return false;
        }
    };


  const stepLabel = (stepId) => {
    switch (stepId) {
      case 0:
        if(request.APPLICATION_STATUS === 'S') return 'Submitted'
        break;

      case 1:
        return request.OFFER_STATUS;

      case 2:
        return request.CONTRACT_STATUS;

      case 3:
        return getContractDate();

      case 4:
        return request.DISBURSED_ON;

      default:
        return false;
    }
  };



  return (
      <>
          <div className={'stepper-progress-container'}>
              <div className={'stepper-progress-wrapper '}>
                  {steps.map((step,index) => (
                      <div className="progress-step-item" key={step.id}>
                          { index <= currentStep  && index !== currentStep && <p className={`step-tooltip ${request.OFFER_STATUS === 'REJECTED' && index === 2 && 'rejected'}  ${request.status}`}>{stepLabel(index)}</p>}
                          <div className={`progress-step ${request.OFFER_STATUS === 'REJECTED' && index === 2 && 'rejected'} ${index <= currentStep && 'active'}  ${index === currentStep && 'last-active'}`}></div>
                          <p className="step-title">{step.stepTitle}</p>
                          <p className="date">{stepDate(step.id) && moment(stepDate(step.id))?.format('L')}</p>
                      </div>
                  ))}
              </div>
          </div>

          <div className={'stepper-progress-container-responsive'}>
              <div className={'stepper-progress-wrapper-responsive'}>
                  {steps.map((step,index) => (
                      <div className="progress-step-item-responsive" key={step.id}>
                          <div className={`progress-step-responsive ${request.OFFER_STATUS === 'REJECTED' && index === 2 && 'rejected'} ${request.CONTRACT_STATUS === 'REJECTED' && index === 3 && 'rejected'} ${index <= currentStep && 'active'}  ${index === currentStep && 'last-active'}`}></div>

                          <div>
                                <p className="step-title-responsive">{step.stepTitle}</p>
                                <p className="date">{stepDate(step.id) && moment(stepDate(step.id))?.format('L')}</p>
                            </div>
                      </div>
                  ))}
              </div>
          </div>
      </>


  )
}