const dev = {
  API_ENDPOINT: 'https://api-mob-stg.tamweel-aloula.com/v1/',
  PORTAL_API_ENDPOINT: 'https://api-mob-stg.tamweel-aloula.com/portal/',
};

const prod = {
  API_ENDPOINT: 'https://mdigital.tamweel-aloula.com/v1/',
  PORTAL_API_ENDPOINT: 'https://mdigital.tamweel-aloula.com/portal/',
};

const test = {
  API_ENDPOINT: 'https://api-mob-stg.tamweel-aloula.com/v1/',
  PORTAL_API_ENDPOINT: 'https://api-mob-stg.tamweel-aloula.com/portal/',
};

const uat = {
  API_ENDPOINT: 'https://api-mob-stg.tamweel-aloula.com/v1/',
  PORTAL_API_ENDPOINT: 'https://api-mob-stg.tamweel-aloula.com/portal/',
};

//console.log("process.env.REACT_APP_NODE_ENV",process.env.REACT_APP_NODE_ENV);
//console.log("process.env.NODE_ENV",process.env.NODE_ENV);
const getEnvironment = () => {
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    case 'uat':
      return uat;
    default:
      return dev;
  }
};

const env = getEnvironment();

export default env;
