import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Tooltip } from "antd";
import RequestIcon from "assets/icons/request.svg";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "shared/components/Table";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import routes, { appRoute } from "shared/constants/routes";
import { AppstoreOutlined, ClockCircleOutlined, EditOutlined, EyeOutlined, FolderOpenOutlined, TableOutlined, ToTopOutlined, LoadingOutlined } from "@ant-design/icons";
import { useGetUserAppsQuery, useGetPersonalInformationMutation } from "services/auth";
import { toast } from "react-toastify";
import { RightOutlined, FileTextOutlined, TagsOutlined, TeamOutlined, ReloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "../../contexts/TranslationContext";
import dayjs from "dayjs";
import env from "shared/constants/env";
import RequestsFullCardsView from "../../components/Home/RequestsFullCardsView/RequestsFullCardsView";

const getStatusLabel = (statusId) => {
	switch (statusId) {
		case "D":
			return "Draft";
		case "S":
			return "Submitted";
		default:
			return "-";
	}
};

const RequestsViewToggleButton = (props) => {
	const isGrid = useCallback(() => props?.state === "grid", [props?.state]);

	return <Button onClick={props?.toggleRequestsView} icon={!isGrid() ? <AppstoreOutlined /> : <TableOutlined />}></Button>;
};

const RequestsGridCard = (props) => {
	const data = props?.data || {};
	const navigate = useNavigate();
	const { translate } = useTranslation();
	const isDraft = useCallback(() => data?.status?.toLowerCase() === "draft", [data?.status]);

	return (
		<Card
			title={`${translate("HOME.APPL_NO")}: ${data?.APPLID}`}
			extra={<Tooltip title={isDraft() ? "Draft" : "Ready"}>{isDraft() ? <ClockCircleOutlined /> : <ToTopOutlined />}</Tooltip>}
			actions={[
				<Button
					icon={isDraft() ? <EditOutlined /> : <EyeOutlined />}
					onClick={() => {
						//view Application
						if (data.APPLICATION_STATUS === "S") {
							navigate(`/app/${routes.VIEW_REQUEST}/${data.APPLID}`);
						}
					}}
					type='text'>
					{isDraft() ? translate("HOME.COMPLETE") : translate("HOME.VIEW")}
				</Button>,
			]}>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					{translate("HOME.PRODUCT")}: <strong>{data?.PRODUCT_NAME ? `${data?.PRODUCT_NAME} / ${data?.PRODUCT_NAME_AR}` : "-"}</strong>
				</Col>
				<Col span={24}>
					{translate("HOME.CONTRACT")}: <strong>{data?.CONTRACT_STATUS || "-"}</strong>
				</Col>
				<Col span={24}>
					{translate("HOME.STATUS")}: <strong className={isDraft() ? "text-yellow-500" : "text-green-600"}>{data?.status || "-"}</strong>
				</Col>
			</Row>
		</Card>
	);
};

const RequestsGridView = (props) => {
	const requests = props?.requests || [];

	if (Array.isArray(props?.requests) && props?.requests.length === 0) {
		return (
			<div className='mt-6 text-gray-300 text-center flex flex-col gap-2'>
				<div>
					<FolderOpenOutlined style={{ fontSize: 32 }} />
				</div>
				<em>No data</em>
			</div>
		);
	}

	return (
		<Row gutter={[16, 16]}>
			{requests.map((request, i) => (
				<Col key={i} sm={24} lg={8}>
					<RequestsGridCard data={request} />
				</Col>
			))}
		</Row>
	);
};

export default function Home() {
	const [user, setUser] = useState({});
	const [view, setView] = useState("fullCard");
	const { data, isLoading, refetch } = useGetUserAppsQuery(JSON.parse(localStorage.getItem("@@TAMWEEL_USER"))?.IDB_USR_ID);
	const [fetchPersonalInfo, { isLoading: personalInfoLoading }] = useGetPersonalInformationMutation();


	const { translate, language } = useTranslation();

	const columnsV1 = [
		{
			title: translate("OFFER_TABLE.APP_NO"),
			dataIndex: "APPLID",
			key: "APPLID",
			sorter: () => true,
		},
		{
			title: translate("OFFER_TABLE.PRODUCT_NAME"),
			dataIndex: "productName",
			key: "productName",
			sorter: () => true,
		},
		{
			title: translate("OFFER_TABLE.CONTRACT"),
			dataIndex: "contractStatus",
			key: "contractStatus",
			sorter: () => true,
		},
		{
			title: translate("OFFER_TABLE.STATUS"),
			dataIndex: "status",
			key: "status",
			sorter: () => true,
		},
	];

	const actionsv1 = [
		{
			id: 0,
			icon: FileTextOutlined,
			label: translate("OFFER_TABLE.CONTRACT"),
			actionKey: "Contract",
		},
		{
			id: 1,
			icon: TagsOutlined,
			label: translate("OFFER_TABLE.OFFER"),
			actionKey: "Offer",
		},
		// {
		// 	id: 2,
		// 	icon: ReloadOutlined,
		// 	label: translate("OFFER_TABLE.REFRESH"),
		// 	actionKey: "refresh",
		// },
		{
			id: 2,
			icon: DeleteOutlined,
			label: translate("OFFER_TABLE.DELETE"),
			actionKey: "Delete",
		},
		// {
		// 	id: 4,
		// 	icon: FileTextOutlined,
		// 	label: translate("OFFER_TABLE.VIEW_CONTRACT"),
		// 	actionKey: "View Contract",
		// },
		{
			id: 3,
			icon: EditOutlined,
			label: translate("OFFER_TABLE.EDIT"),
			actionKey: "Edit",
		},
	];

	const toggleRequestsView = useCallback(() => {
		setView((view) => (view === "grid" ? "table" : "grid"));
	}, []);

	const navigate = useNavigate();

	let tableData = data?.map((row) => ({
		...row,
		productName: row?.PRODUCT_NAME ? `${row?.PRODUCT_NAME} / ${row?.PRODUCT_NAME_AR}` : "-",
		contractStatus: row?.CONTRACT_STATUS ? row?.CONTRACT_STATUS : "-",
		status: getStatusLabel(row?.APPLICATION_STATUS),
	}));

	useEffect(() => {
		let user;

		try {
			user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

			user.APP_CREATED = false;

			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
			setUser(user);
		} catch (e) {
			console.error(e);
		}
	}, [data]);

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)));

		refetch();
	}, []);

	const onDelete = (appId) => {
		fetch(env.API_ENDPOINT + `app/delete-app?appId=${appId}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res?.result?.deleted) {
					toast.success("App has been deleted.");
					refetch();
				} else {
					toast.error("Server Error");
				}
			});
	};
	const onEdit = (applId) => {
		navigate(appRoute(routes.CREATE_REQUEST + "/" + routes.CREATE_REQUEST_PERSONAL_DETAILS) + `?appl_=${applId}`);
	}

	const handleCreateNewApplication = async () => {
		if (data?.length > 0 && Array.isArray(data) && data.some(item => item.CONTRACT_STATUS !== 'SIGNED')) {
			toast.error(translate("APPLICATION_ALREADY_EXISTS"));
			return;
		}
		if (data) {
			try {
				const response = await fetchPersonalInfo({ id: `${JSON.parse(localStorage.getItem("@@TAMWEEL_USER"))?.USER_ID}` }).unwrap().catch((res) => {
					// console.log("Personal info err resp:", res);
				});
				if (response?.payload?.data?.address?.personNationalAddressInfo?.Addresses) {
					navigate(appRoute(routes.CREATE_REQUEST + "/" + routes.CREATE_REQUEST_PERSONAL_DETAILS));
				}
				else {
					toast.error(response?.payload?.data?.address?.error?.message || 'Please update your national address and try again');
				}
			} catch (error) {
				//for now this will be error for personalInfo
				// console.log("Personal info resp er:", error);
				return toast.error(translate("Something went wrong"));
			}
		} else {
			return toast.error(translate("Something went wrong"));
		}
	};

	const noRequests = () => {
		if (!tableData || tableData.length === 0) {
			return (
				<div className={'no-result'}>
					<img src={'https://res.cloudinary.com/dlg8sln4t/image/upload/v1727442315/Group_3_okh2yo.png'} />
					<p>{translate('HOME.NO_REQUESTS')} </p>
				</div>
			)
		}
	}

	return (
		<>
			<section className='mb-4'>
				<h1 className='text-4xl text-gray-500 mb-2'>
					{translate("HOME.Hi")}, <strong className='text-gray-700'>{language?.code === "en" ? user?.FIRST_NAME : user?.FIRST_NAME_AR}</strong>!
				</h1>
				{user?.LAST_LOGIN && (
					<div className='text-gray-500'>
						{translate("HOME.LAST_LOGIN")}: <strong> {dayjs(user?.LAST_LOGIN).format("dddd, D MMM ")}</strong>
					</div>
				)}
			</section>
			<section className='mb-4'>
				{personalInfoLoading ? (<div className="items-center py-4">
					<LoadingOutlined style={{ fontSize: 24 }} spin />
				</div>) :
					(!Array.isArray(data) || data.length === 0 || (Array.isArray(data) && data.some(item => item.CONTRACT_STATUS === 'SIGNED'))) && (
						<button style={{ borderRadius: '16px' }} className='bg-white border border-black/10 rounded-md py-4 px-5 cursor-pointer disabled:text-gray-500 disabled:bg-gray-200 disabled:cursor-not-allowed ' type='default' size='large' onClick={handleCreateNewApplication}>
							<div className='flex items-center justify-between gap-3'>
								<div className='bg-primary rounded-lg p-2'>
									<img alt='' src={RequestIcon} className='w-8 h-8' />
								</div>
								<div className='text-gray-600'>{translate("HOME.CREATE_NEW")}</div>
							</div>
						</button>
					)
				}
			</section>
			<section>
				<div className='bg-white rounded border p-6'>
					<div className='flex items-center justify-between mb-4'>
						<h2 className='text-2xl font-bold'>{translate("HOME.REQUEST")}</h2>
						{/*   <RequestsViewToggleButton state={view} toggleRequestsView={toggleRequestsView} /> */}
					</div>
					{/*<div className='flex mb-4'>*/}
					{/*	<div className='flex-1'></div>*/}
					{/*	<Input className='flex-1' placeholder={translate("HOME.SEARCH")} prefix={<SearchOutlined />} />*/}
					{/*</div>*/}
					{view === "table" && (
						<Table
							onDelete={onDelete}
							onEdit={onEdit}
							refresh={refetch}
							actions={actionsv1}
							bordered
							columns={columnsV1}
							data={tableData}
							rowSelection={{
								type: "checkbox",
							}}
						/>
					)}
					{view === "grid" && <RequestsGridView requests={tableData} />}
					{view === "fullCard" && <RequestsFullCardsView onEdit={onEdit} onDelete={onDelete} requests={tableData} />}
					{!isLoading && noRequests()}
				</div>
			</section>
		</>
	);
}

