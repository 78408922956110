import './requestCardsStyle.css'
import StepperProgress from "./StepperProgress/StepperProgress";
import routes, { appRoute } from "../../../shared/constants/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../contexts/TranslationContext";
import { EyeOutlined } from "@ant-design/icons";

export default function RequestsFullCardsView({ requests, onDelete, onEdit }) {
    const navigate = useNavigate();
    const { translate } = useTranslation();

    // handle view application
    const viewApplication = (request) => {
        if (request.APPLICATION_STATUS === "S") {
            navigate(`/app/${routes.VIEW_REQUEST}/${request.APPLID}`);
        }
    }


    const viewOffer = (request) => {
        navigate(`/app/${routes.VIEW_OFFER}/${request?.APPLID}`);
    }

    const viewContract = (request) => {
        navigate(`/app/${routes.VIEW_CONTRACT}/${request?.APPLID}/${request.CONTRACT_STATUS}`);
    }

    const applicationStatus = (request) => {
        return request.CONTRACT_STATUS || request.OFFER_STATUS || request.APPLICATION_STATUS === 'S' && 'Submitted' || request.APPLICATION_STATUS === 'D' && 'DRAFT'
    }

    const errorStatusClass = (request) => {
        if (request.OFFER_STATUS === 'REJECTED' || request.CONTRACT_STATUS === 'REJECTED')
            return 'rejected'
    }



    return (
        <div>
            {requests && requests.map((request, index) => {
                return (
                    <div className="request-card-wrapper" key={index}>
                        <div className="request-card-header">
                            <div>
                                <p className="title">{translate('HOME.DIGITAL_APP_ID')}: {request.APPLID || 'N/A'}</p>
                                <p className="text">{translate('HOME.APPL_NO')}: {request.KASTLE_APPL_ID || 'N/A'}</p>
                                <p className={`responsive-app-status ${errorStatusClass(request)}`}>{applicationStatus(request)}</p>
                            </div>
                            <div className="request-card-header-buttons">
                                {request.APPLICATION_STATUS === 'S' && <button onClick={() => viewApplication(request)} className="button-wrapper" style={{ fontSize: '25px' }}><EyeOutlined /></button>}
                                {request.APPLICATION_STATUS === 'D' && <button onClick={() => onEdit(request.APPLID)} className="button-wrapper">{translate("OFFER_TABLE.EDIT")}</button>}
                                {request.APPLICATION_STATUS === 'D' && <button onClick={() => onDelete(request.APPLID)} className="button-wrapper">{translate("DELETE")}</button>}

                                {request.OFFER_STATUS === 'GENERATED' && <button onClick={() => viewOffer(request)} className="button-wrapper">{translate("HOME.VIEW_OFFER")}</button>}
                                {request.CONTRACT_STATUS === 'GENERATED' && <button onClick={() => viewContract(request)} className="button-wrapper">{translate("HOME.CONTRACT_SIGNING")}</button>}
                                {request.CONTRACT_STATUS === 'SIGNED' && <button onClick={() => viewContract(request)} className="button-wrapper">{translate("HOME.VIEW_CONTRACT")}</button>}
                                {/*<button onClick={() =>viewApplication(request)} className="button-wrapper">{translate("UPLOAD_DOCUMENT")}</button>*/}

                            </div>
                        </div>
                        <StepperProgress request={request} />

                        {request.OFFER_DECISION && <div className={'qaurar-comments'}>
                            <span>{translate('QARAR_COMMENTS')}</span>
                            {request.OFFER_DECISION}
                        </div>}

                        <div className="request-card-header-buttons-responsive">
                            {request.APPLICATION_STATUS === 'S' && <button onClick={() => viewApplication(request)} className="button-wrapper" style={{ fontSize: '25px' }}><EyeOutlined /></button>}
                            {request.APPLICATION_STATUS === 'D' && <button onClick={() => onEdit(request.APPLID)} className="button-wrapper">{translate("OFFER_TABLE.EDIT")}</button>}
                            {request.APPLICATION_STATUS === 'D' && <button onClick={() => onDelete(request.APPLID)} className="button-wrapper">{translate("DELETE")}</button>}

                            {request.OFFER_STATUS === 'GENERATED' && <button onClick={() => viewOffer(request)} className="button-wrapper">{translate("HOME.VIEW_OFFER")}</button>}
                            {request.CONTRACT_STATUS === 'GENERATED' && <button onClick={() => viewContract(request)} className="button-wrapper">{translate("HOME.CONTRACT_SIGNING")}</button>}
                            {request.CONTRACT_STATUS === 'SIGNED' && <button onClick={() => viewContract(request)} className="button-wrapper">{translate("HOME.VIEW_CONTRACT")}</button>}
                            {/*<button onClick={() =>viewApplication(request)} className="button-wrapper">{translate("UPLOAD_DOCUMENT")}</button>*/}

                        </div>
                    </div>
                );
            })}
        </div>
    );
}
