import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Space } from "antd";
import BellIcon from "assets/icons/bell.svg";
import LogoutIcon from "assets/icons/logout.svg";
import SettingsIcon from "assets/icons/settings.svg";
import Logo from "assets/svg/logo-color.svg";
import useAuthHook from "hooks/useAuthHook";
import { useEffect, useMemo, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom"; // Import useLocation
import { useLogoutMutation } from "services/auth";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import { LANGUAGES, useTranslation } from "../../../contexts/TranslationContext";

const LanguageDropdown = () => {
  const { language, setLanguage } = useTranslation();

  const languages = useMemo(
    () =>
      Object.keys(LANGUAGES).map((key) => ({
        key: LANGUAGES[key]?.code,
        label: (
          <span onClick={() => setLanguage(LANGUAGES[key])}>
            {LANGUAGES[key]?.name}
          </span>
        ),
        disabled: language?.code === LANGUAGES[key]?.code,
      })),
    [language, setLanguage]
  );

  return (
    <Dropdown menu={{ items: languages }}>
      <Space>
        <span className="font-bold">{language?.shortname}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default function Layout({ children }) {
  const { logout } = useAuthHook();
  const [confirmModal, setConfirmModal] = useState(false);
  const [doLogout, { isLoading }] = useLogoutMutation();
  const { translate } = useTranslation();
  const location = useLocation();

  useEffect(() => {}, []);

  const onLogout = () => {
    setConfirmModal(true);
  };

  return (
    <>
      <div className="bg-gray-100/50 h-screen">
        <div className="relative w-full h-16 with-splash-bg"></div>
        <main className="relative -mt-8 container lg:px-8 mx-auto">
          <header className="rounded border border-gray-200 min-h-16 mb-4 px-4 py-8 bg-white flex items-center">
            <div>
              <Link to="/app/home">
                <img alt="App logo" src={Logo} className="cursor-pointer" />
              </Link>
            </div>
            <div className="flex-grow"></div>
            <div className="flex items-center gap-8">
              <ul className="flex items-center gap-4">
                {/* <li className="cursor-pointer">
                  <img alt="Header icon" src={BellIcon} />
                </li> */}
                {location.pathname !== "/app/settings" && (
                  <li className="cursor-pointer">
                    <Link to="/app/settings">
                      <img alt="Settings icon" src={SettingsIcon} />
                    </Link>
                  </li>
                )}
                <li className="cursor-pointer" onClick={onLogout}>
                  <img alt="Logout icon" src={LogoutIcon} />
                </li>
              </ul>
              <LanguageDropdown />
            </div>
          </header>
          <Outlet />
        </main>
      </div>
      <Modal
        open={confirmModal}
        title={translate("HOME.ARE_YOU_SURE")}
        onCancel={() => setConfirmModal(false)}
        okType="dashed"
        okButtonProps={{ loading: isLoading }}
        onOk={async () => {
          const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
          const userId = JSON.parse(user)?.IDB_USR_ID;

          if (userId) {
            await doLogout({ userId });
            logout();
            setConfirmModal(false);
          } else {
          }
        }}
      ></Modal>
    </>
  );
}
