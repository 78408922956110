import { FormDrawer, buildInitialValues, buildValidationSchema } from 'Components';
import { LoadingOutlined } from "@ant-design/icons";
import { API_URL } from 'constants';
import { withFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { PATCH_LOAN_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext } from './CreateRequestContext';
import { loanDetailsSchema } from './schemas';
import * as LOCAL_STORAGE_KEYS from 'shared/constants/localStorageKeys';
import { useTranslation } from "../../../contexts/TranslationContext";
import env from 'shared/constants/env';

const LoanDetails = withFormik({
  mapPropsToValues: () => buildInitialValues(loanDetailsSchema({
    hideRequestedLoanAmount: true,
  })),
  validationSchema: buildValidationSchema(loanDetailsSchema({
    hideRequestedLoanAmount: true,
  })),
  validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true
})(props => {
  const { state, dispatch } = useCreateRequestContext();
  const [productsList, setProductsList] = useState([]);
  const [subProductsList, setSubProductsList] = useState([]);
  const { translate } = useTranslation();
  const [initialDataLoading, setInitialDataLoading] = useState(true)


  const userId = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
  )?.USER_ID;

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 3 });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_STEP_VALIDITY,
      payload: {
        stepIndex: 3,
        valid: props.isValid,
      },
    });
  }, [dispatch, props.isValid]);

  useEffect(() => {
    dispatch({
      type: PATCH_LOAN_DETAILS,
      payload: {
        ...props.values,
        isTawarooq: props.values?.typeOfLoan === '328',
        isAutofacility: props.values?.subproductId === '250' || props.values?.subproductId === '331'
      },
    });
  }, [dispatch, props.values]);

  useEffect(() => {
    fetch(`${env.API_ENDPOINT}lookup/products?status=A`)
      .then(res => res.json())
      .then(res => {
        setProductsList(
          res.payload.rows
            .filter(product => {
              // Hide Tawarooq if user is non-Saudi.
              if ((userId && String(userId)?.[0] === '2') && product.PRODUCT_CODE === 'APL') {
                return false;
              } else {
                return true;
              }
            })
            .map(product => ({
              label: product.PRODUCT_NAME,
              value: JSON.stringify(product),
              labelAr: product.PRODUCT_NAME_AR
            }))
        );
      })
      .catch(() => {
        toast.error('Failed to load products.');
      }).finally(() => {
        setInitialDataLoading(false)
      })
  }, []);

  useEffect(
    () => {
      let product = props.values?.productId;
      if (!product) {
        return;
      }
      product = JSON.parse(product);
      setSubProductsList([]);
      fetch(`${env.API_ENDPOINT}lookup/sub-products?status=A&productCode=${product.PRODUCT_CODE}`)
        .then(res => res.json())
        .then(res => {
          setSubProductsList(res.payload.rows.map(row => ({
            label: row.SUBPRODUCT_NAME,
            value: String(row.SUBPRODUCTID),
            labelAr: String(row.SUBPRODUCT_NAME_AR),
          })));
        })
        .catch(() => {
          toast.error('Failed to load sub-products.');
        });
    }, [
    props.values?.productId,
  ]);

  useEffect(() => {
    if (String(props.values?.tenure).length > 2) {
      props.setFieldValue('tenure', String(props.values?.tenure).substring(0, 2));
    }
  }, [props.values?.tenure]); // eslint-disable-line

  useEffect(() => {
    props.setValues({
      ...state.loanDetails,
      tenure: state.loanDetails.tenure ? state.loanDetails.tenure : '12'
    })
  }, [])


  // useEffect(() => {
  //   if (state.editUserApp && !state.editUserAppDump?.loanDetails) {
  //     const selectedProduct = productsList.find(product => product.value.includes(`"PRODUCTID":${state.editUserApp.productId}`));
  //     if (selectedProduct) {
  //       props.setValues({
  //         productId: selectedProduct.value,
  //         subproductId: state.editUserApp.subproductId,
  //         tenure: state.editUserApp.tenure,
  //         request_amount: state.editUserApp.request_amount
  //       })
  //       dispatch({
  //         type: 'SET_EDIT_USER_APP_DUMP',
  //         payload: {
  //           ...state.editUserAppDump,
  //           loanDetails: true,
  //         },
  //       });
  //     }
  //   }
  // }, [state.editUserApp, productsList])



  const schemaFields = useMemo(
    () => {
      let hideRequestedLoanAmount = true;

      if (props.values?.productId) {
        let prod = JSON.parse(props.values?.productId);
        hideRequestedLoanAmount = prod.PRODUCTID === 217;
      }
      return loanDetailsSchema({
        hideRequestedLoanAmount: hideRequestedLoanAmount,
        productsList,
        subProductsList

      })
    },
    [productsList, subProductsList, props.values?.productId]
  );

  if (initialDataLoading) {
    return (
      <div className='p-5 bg-white flex flex-col gap-4'>
        <div className='flex gap-4'>
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_LOAN_DETAILS")}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <h2 className="font-bold text-xl mb-4">{translate('CREATE_REQUEST.LOAN_DETAILS')}</h2>

      <div className="mb-4">
        <FormDrawer schema={{
          fields: schemaFields,
        }} />
      </div>
    </>
  );
});

export default LoanDetails;
