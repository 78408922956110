import { FormDrawer, buildInitialValues, buildValidationSchema } from 'Components';
import { withFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { PATCH_FINANCIAL_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext, SET_EDIT_APPL_LOAD_FLAGS } from './CreateRequestContext';
import { financialDetailsSchema } from './schemas';
import { useTranslation } from "../../../contexts/TranslationContext";

const FinancialDetails = withFormik({
  mapPropsToValues: () => buildInitialValues(financialDetailsSchema({
    hasAdditionalIncome: true,
  })),
  validationSchema: buildValidationSchema(financialDetailsSchema({
    hasAdditionalIncome: true
  })),
  enableReinitialize: true,
})(props => {
  const { state, dispatch } = useCreateRequestContext();
  const { translate } = useTranslation();

  useEffect(() => {
    //component did mount
    props.setFieldValue('foodExpenses', state.financialDetails?.foodExpenses ? state.financialDetails?.foodExpenses : 0);
    props.setFieldValue('housingExpenses', state.financialDetails?.housingExpenses ? state.financialDetails?.housingExpenses : 0);
    props.setFieldValue('domesticWorkers', state.financialDetails?.domesticWorkers ? state.financialDetails?.domesticWorkers : 0);
    props.setFieldValue('educationExpenses', state.financialDetails?.educationExpenses ? state.financialDetails?.educationExpenses : 0);
    props.setFieldValue('healthCareExpenses', state.financialDetails?.healthCareExpenses ? state.financialDetails?.healthCareExpenses : 0);
    props.setFieldValue('insuranceExpenses', state.financialDetails?.insuranceExpenses ? state.financialDetails?.insuranceExpenses : 0);
    props.setFieldValue('anyExpectedFutureExpense', state.financialDetails?.anyExpectedFutureExpense ? state.financialDetails?.anyExpectedFutureExpense : 0);
    props.setFieldValue('commAndTransExpenses', state.financialDetails?.commAndTransExpenses ? state.financialDetails?.commAndTransExpenses : 0);
  }, [])

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 2 });

  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_STEP_VALIDITY,
      payload: {
        stepIndex: 2,
        valid: props.isValid,
      },
    });
  }, [dispatch, props.isValid]);

  useEffect(() => {
    dispatch({
      type: PATCH_FINANCIAL_DETAILS,
      payload: props.values,
    });
  }, [dispatch, props.values]);

  useEffect(
    () => {
      const totalExpenses = [
        props.values?.foodExpenses,
        props.values?.housingExpenses,
        props.values?.domesticWorkers,
        props.values?.educationExpenses,
        props.values?.healthCareExpenses,
        props.values?.communicationAndTransportationExpenses,
        props.values?.insuranceExpenses,
        props.values?.anyExpectedFutureExpense,
      ].reduce((totalExpenses, expense) => Number(totalExpenses) + (Number(expense) || 0), 0);

      props.setFieldValue('totalExpenses', isNaN(totalExpenses) ? 0 : totalExpenses);
    },
    // eslint-disable-next-line
    [
      props.values?.foodExpenses,
      props.values?.housingExpenses,
      props.values?.domesticWorkers,
      props.values?.educationExpenses,
      props.values?.healthCareExpenses,
      props.values?.communicationAndTransportationExpenses,
      props.values?.insuranceExpenses,
      props.values?.anyExpectedFutureExpense,
    ]
  );

  const schemaFields = useMemo(
    () => financialDetailsSchema({
      hasAdditionalIncome: props.values?.hasAdditionalIncome === '2',
      hasExistingLoan: props.values?.hasExistingLoan === '2',
    }),
    [props.values?.hasAdditionalIncome, props.values?.hasExistingLoan]
  );

  return (
    <>
      <h2 className="font-bold text-xl mb-4">{translate('CREATE_REQUEST.FINANCIAL_DETAILS')}</h2>

      <div className="mb-4">
        <FormDrawer schema={{
          fields: schemaFields,
        }} />
      </div>
    </>
  );
});

export default FinancialDetails;
