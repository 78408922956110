import {
	AutoComplete,
	Button,
	Col,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	notification,
	DatePicker,
} from "antd";
import { Form, useField, useFormikContext, withFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "./contexts/TranslationContext";
import dayjs from "dayjs";

const Label = ({ name, label, required = false }) =>
	label && (
		<label htmlFor={name} className='flex gap-2 mb-2 text-sm'>
			<span>{label}</span>
			{required && <span className='text-red-500'>*</span>}
		</label>
	);

const ErrorMessage = ({ error, touched }) =>
	error && touched && <div className='text-sm text-red-500 mt-2'>{error}</div>;

const AutoCompleteControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const [options, setOptions] = useState(props?.options || []);
	const [filteredOptions, setFilteredOptions] = useState(props?.options || []);
	const [isTyping, setIsTyping] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const { translate } = useTranslation();

	const onSelect = (data, option) => {
		helpers.setValue(option.value);
		// setSelectedOption(option.value);
		setInputValue(option.label);
	};

	const onChange = (data, option) => {
		// setInputValue(data);
		// setSelectedOption(option); // to remove selected option when user types  something wich doesn't match with any option
	};

	return (
		<>
			<Label
				label={props?.label}
				name={props?.name}
				required={props?.required}
			/>
			<AutoComplete
				className='w-full min-h-12'
				name={props.name}
				id={props.name}
				status={meta.error && meta.touched ? "error" : null}
				placeholder={props.label}
				disabled={props?.disabled}
				options={options}
				onSelect={onSelect}
				onChange={onChange}
				value={inputValue}
				filterOption={(inputValue, option) =>
					option.labelAr.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
				}
				suffixIcon={<SearchOutlined />}
				{...field}
			/>
			<ErrorMessage error={meta.error} touched={meta.touched} />
		</>
	);
};

const TextControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const { translate } = useTranslation();

	/*   useEffect(() => {
	// Setting the default value for the field

	helpers.setValue(props.defaultValue);
  }, [props.defaultValue]); */
	return (
		<>
			<Label
				label={translate("CREATE_REQUEST." + props?.label)}
				name={props?.name}
				required={props?.required}
			/>
			<Input
				className='w-full min-h-12'
				name={props.name}
				maxLength={props?.maxLength ?? 255}
				id={props.name}
				status={meta.error && meta.touched ? "error" : null}
				placeholder={translate("CREATE_REQUEST." + props.label)}
				disabled={props?.disabled}
				addonBefore={props?.addonBefore}
				{...field}
			/>
			<ErrorMessage
				error={meta.error && translate("CREATE_REQUEST." + meta.error)}
				touched={meta.touched}
			/>
		</>
	);
};

const NumberControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const { translate } = useTranslation();

	const handleChange = (value) => {
		helpers.setValue(String(value));
	};
	/*   useEffect(() => {
	// Setting the default value for the field

	helpers.setValue(props.defaultValue);
  }, [props.defaultValue]); */
	return (
		<>
			<Label
				label={translate("CREATE_REQUEST." + props?.label)}
				name={props?.name}
				required={props?.required}
			/>
			<InputNumber
				type='number'
				className='w-full min-h-12'
				name={props.name}
				id={props.name}
				status={meta.error && meta.touched ? "error" : null}
				placeholder={translate("CREATE_REQUEST." + props.label)}
				disabled={props?.disabled}
				min={0}
				{...field}
				onChange={handleChange}
				addonBefore={props?.addonBefore}
				onInput={handleChange}
			/>
			<ErrorMessage
				error={meta.error && translate("CREATE_REQUEST." + meta.error)}
				touched={meta.touched}
			/>
		</>
	);
};

const SelectControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const { translate, language } = useTranslation();

	const handleChange = (value) => {
		helpers.setValue(String(value));
		helpers.setTouched(true); //so that meta.touched becomes true
	};
	const handleSelect = (value) => {
		helpers.setValue(String(value));
		if(props?.label==='Tenure'){
			helpers.setTouched(true); //so that meta.touched becomes true
		}
	};
	let options = props?.options || [];
	if (language.code === "ar") {
		options = options.map((opt) => {
			return {
				label: opt.labelAr ?? opt.label,
				value: opt.value,
			};
		});
	}

	return (
		<>
			<Label
				label={translate("CREATE_REQUEST." + props?.label)}
				name={props?.name}
				required={props?.required}
			/>
			<Select
				name={props.key}
				id={props.key}
				className='w-full min-h-12'
				status={meta.error && meta.touched ? "error" : null}
				options={options}
				onChange={handleChange}
				onSelect={handleSelect}
				onClear={() => helpers.setValue(null)}
				value={field.value}
				showSearch={true}
				filterOption={(input, option) =>
					option.label.toLowerCase().includes(input.toLowerCase())
				}
				placeholder={translate("CREATE_REQUEST." + props.label)}
				disabled={props?.disabled}
				{...field}
			/>
			<ErrorMessage
				error={meta.error && translate("CREATE_REQUEST." + meta.error)}
				touched={meta.touched}
			/>
		</>
	);
};

const RadioControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const { translate } = useTranslation();

	return (
		<>
			<Label
				label={translate("CREATE_REQUEST." + props?.label)}
				name={props?.name}
				required={props?.required}
			/>
			<Radio.Group {...field}>
				{props?.options.map((option) => (
					<Radio key={option.value} value={option.value}>
						{translate(option.label)}
					</Radio>
				))}
			</Radio.Group>
		</>
	);
};

const DatePickerControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const { translate } = useTranslation();
	const handleChange = (value, value2) => {
		helpers.setValue(String(value2));
	};

	const disableFutureDates = (current) => {
		// Disable dates after today
		return current && current > dayjs().endOf("day");
	};


	return (
		<>
			<Label
				label={translate(props?.label)}
				name={props?.name}
				required={props?.required}
			/>
			<DatePicker
				format='DD/MM/YYYY'
				className='w-full min-h-12'
				disabled={field?.disabled}
				disabledDate={disableFutureDates}
				placeholder={translate(field.label)}
				onChange={handleChange}
			/>
		</>
	);
};

const FormField = ({ field }) => {
	switch (field.type) {
		case "auto-complete":
			return (
				<AutoCompleteControl
					name={field.key}
					label={field.label}
					required={field?.required}
					disabled={field?.disabled}
					options={field?.options || []}
				/>
			);
		case "text":
			return (
				<TextControl
					maxLength={field?.props?.maxLength ?? 255}
					name={field.key}
					label={field.label}
					required={field?.required}
					disabled={field?.disabled}
					addonBefore={field?.addonBefore}
				/>
			);
		case "select":
			return (
				<SelectControl
					name={field.key}
					label={field.label}
					required={field?.required}
					disabled={field?.disabled}
					options={field?.options || []}
					{...field.props}
				/>
			);
		case "number":
			return (
				<NumberControl
					name={field.key}
					label={field.label}
					required={field?.required}
					disabled={field?.disabled}
				/>
			);
		case "radio":
			return (
				<RadioControl
					name={field.key}
					label={field.label}
					required={field?.required}
					options={field?.options || []}
				/>
			);
		case "datePicker":
			return (
				<DatePickerControl
					name={field.key}
					label={field.label}
					required={field?.required}
					disabled={field?.disabled}
				/>
			);

		default:
			return <></>;
	}
};

export const FormDrawer = ({ schema }) => {
	return (
		<Row gutter={[16, 16]}>
			{schema.fields.map((field, i) => (
				<Col xs={24} lg={field?.span} key={i}>
					<FormField field={field} />
				</Col>
			))}
		</Row>
	);
};

const genderOptions = [
	{ label: "Male", value: "Male" },
	{ label: "Female", value: "Female" },
];

const dependentsOptions = [
	{ label: "Yes", value: "1" },
	{ label: "No", value: "0" },
];

const inquiryFormSchemaFields = (hasDependents = true) => [
	{
		type: "text",
		name: "name",
		label: "YOUR_NAME",
		required: true,
		validation: Yup.string().required("PLEASE_ENTER_NAME"),
	},
	{
		type: "text",
		name: "email",
		label: "YOUR_EMAIL",
		required: true,
		validation: Yup.string()
			.email("USE_VALID_EMAIL")
			.required("PLEASE_TYPE_EMAIL"),
	},
	{
		type: "select",
		name: "gender",
		label: "YOUR_GENDER",
		required: true,
		options: genderOptions,
		validation: Yup.string().required("PLEASE_SELECT_GENDER"),
	},
	{
		type: "radio",
		name: "hasDependents",
		label: "DO_YOU_HAVE_DEPENDENTS",
		options: dependentsOptions,
		value: "0",
		validation: Yup.string(),
	},
	...(hasDependents
		? [
			{
				type: "number",
				name: "numberOfDependents",
				label: "NUMBER_OF_DEPENDENTS",
				validation: Yup.string(),
			},
		]
		: []),
];

const SubmitOnEasterEgg = () => {
	const { values } = useFormikContext();

	useEffect(() => {
		if (values?.name === "Formik") {
			notification.info({ message: "You've discovered an Easter Egg." });
		}
	}, [values]);

	return null;
};

export const buildValidationSchema = (fields) =>
	Yup.object().shape(
		fields.reduce(
			(fields, field) => ({
				...fields,
				[field.key]: field?.validation || Yup.string(),
			}),
			{}
		)
	);

export const buildInitialValues = (fields) =>
	fields.reduce(
		(fields, field) => ({
			...fields,
			[field.key]: field?.value,
		}),
		{}
	);

const InquiryForm = withFormik({
	mapPropsToValues: () =>
		inquiryFormSchemaFields().reduce(
			(fields, field) => ({
				...fields,
				[field.name]: field?.value || "",
			}),
			{}
		),
	validationSchema: Yup.object().shape(
		inquiryFormSchemaFields().reduce(
			(fields, field) => ({
				...fields,
				[field.name]: field.validation,
			}),
			{}
		)
	),
	handleSubmit(values) {
	},
})(function InternalInquiryForm(props) {
	return (
		<Form>
			<FormDrawer
				schema={{
					fields: inquiryFormSchemaFields(props.values.hasDependents === "1"),
				}}
			/>
			<Button
				htmlType='button'
				onClick={() => {
				}}>
				OK
			</Button>
			<SubmitOnEasterEgg />
		</Form>
	);
});

const Components = () => {
	return (
		<>
			<div className='container px-4 mx-auto'>
				<h1 className='text-2xl font-bold rounded-md my-4 p-4 bg-gray-100'>
					Components
				</h1>
				<InquiryForm />
			</div>
		</>
	);
};

export { Components };
