import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import routes from "shared/constants/routes";
import { useEffect } from "react";
import useAuthHook from "hooks/useAuthHook";

export function ProtectedResource({ children }) {
  const { logout } = useAuthHook();

  let user;

  useEffect(() => {
    if (user) {
      interceptor();
    }
  }, [user]);

  try {
    user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
  } catch (e) {
    return <Navigate to={routes.AUTH.SIGN_IN} />;
  }

  if (!user || !user?.IDB_USR_ID) {
    toast.error("Unauthenticated access.");

    return <Navigate to={routes.AUTH.SIGN_IN} />;
  }

  const interceptor = async () => {
    const { fetch: originalFetch } = window;

    let accessToken = null; // Replace "your_access_token_here" with your actual access token
    window.fetch = async (...args) => {
      let [resource, config] = args;
      config = config || {};
      const updatedUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

      if (updatedUser) {
        accessToken = updatedUser.token;
        // Add access token to headers

        // Add access token to headers
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json; charset=utf-8",
        };
      }

      const response = await originalFetch(resource, config);
      if (response.status == 401 || response.status == 403) {
        logout();
      }
      return response;
    };
  };

  return children;
}
