import * as LOCAL_STORAGE_KEYS from 'shared/constants/localStorageKeys';
import getGeoLocation from "../utilities/getGeoLocation";

// Fetch IP Address using an external API
async function getUserIP() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return ''
  }
}

function getBrowserName() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Edg")) {
    return "Microsoft Edge";
  } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
    return "Google Chrome";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Firefox")) {
    return "Mozilla Firefox";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    return "Opera";
  } else {
    return "Unknown Browser";
  }
}

function getOs() {
  return navigator.platform || "Unknown"
}

function getDeviceSettings() {
  const userAgent = navigator.userAgent;

  const isIphone = /iPhone/i.test(userAgent);

  const isTablet = /iPad|Tablet/i.test(userAgent);

  const isMobile = /Mobi|Android/i.test(userAgent) || isIphone;

  const deviceType = isTablet ? "Tablet" : isMobile ? "Mobile" : "Desktop";

  return {
    deviceType: deviceType,
    hardwareVersion: navigator.hardwareConcurrency || "Unknown",
  };
}

// Log all device details
export default async function logApiDetails({ id, url, statusCode }) {
  const { deviceType, hardwareVersion } = getDeviceSettings();
  const ipAddress = await getUserIP();
  const browserName = getBrowserName();
  const os = getOs();
  const { latitude, longitude } = await getGeoLocation();
  const idbUserId = localStorage.getItem(LOCAL_STORAGE_KEYS?.USER)?.IDB_USR_ID

  console.log(
    `%c-------------------\n` +
    `%cIdentifier         : %c${id ?? idbUserId}\n` +
    `%cURL               : %c${url}\n` +
    `%cHttp Status Code  : %c${statusCode}\n\n` +
    `%cTechnical Information:\n` +
    `%cIP Address              : %c${ipAddress}\n` +
    `%cBrowser Name            : %c${browserName}\n` +
    `%cOperating System        : %c${os}\n` +
    `%cDevice Type             : %c${deviceType}\n` +
    `%cDevice Hardware Version : %c${hardwareVersion}\n` +
    `%cDevice-Id               : %c${localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_ID)}\n` +
    `%cGeo-Location            : %c(${latitude}, ${longitude})\n`,
    // Header style
    "color: #2E86C1; font-weight: bold;",
    // Label styles
    "color: #34495E; font-weight: bold;",
    // Value styles
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #2E86C1; font-weight: bold;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;",

    "color: #34495E; font-weight: bold;",
    "color: #1ABC9C;"
  );

}

