import React, { useEffect, useState, useMemo } from "react";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { Button } from "shared/components/Button";
import CancelModal from "./CancelModal";
import AcceptModal from "./AcceptModal";
import { API_URL } from "constants";
import { useNavigate, useParams } from "react-router-dom";
import env from "shared/constants/env";

import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import routes, { appRoute } from "shared/constants/routes";

function ViewContract() {
  const { appId, contractStatus } = useParams();
  const navigate = useNavigate();

  const [pdfURL, setpdfURL] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPdf = async (appId) => {
    try {
      let response = null;
      if (contractStatus === "SIGNED") {
        response = await fetch(`${env.API_ENDPOINT}app/get-signed-contract?appId=${appId}`);
      } else if (contractStatus === "GENERATED") {
        response = await fetch(`${env.API_ENDPOINT}app/get-unsigned-contract`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ appId: appId }),
        });
      }

      const data = await response.json();

      if (data.contract) {
        const base64String = data.contract;
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setpdfURL(url);
      } else {
        toast.error(data?.message || `Couldn't read contract`);
        navigate(appRoute(routes.HOME));
      }
    } catch (error) {
      toast.error("Couldn't read contract");
      navigate(appRoute(routes.HOME));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!appId) {
      navigate(routes.HOME);
      toast.error("Application ID was not specified.");
      return;
    }

    fetchPdf(appId);
  }, [appId, navigate]);

  return (
    <div>
      <Card>
        <div className="flex items-center gap-4">
          <div onClick={() => navigate(`/app/${routes.HOME}`)} className="border border-gray-200 cursor-pointer w-8 h-8 rounded-lg flex items-center justify-center">
            <LeftOutlined />
          </div>
          <h1 className="font-bold text-2xl">Contract</h1>
        </div>

        {loading && (
          <div className="mt-5">
            Loading &nbsp;
            <LoadingOutlined />
          </div>
        )}

        {pdfURL && (
          <div className="mt-5">
            <iframe src={pdfURL} width="100%" height="600px" title="Contract Viewer"></iframe>

            {contractStatus === "GENERATED" && (
              <div className="flex items-center justify-center gap-2 mt-5">
                <CancelModal appId={appId} />
                <AcceptModal appId={appId} />
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
}

export default ViewContract;
